.MainPageContainer{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.MainPageContainer-PostsContainer{
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--header-height-full));
    overflow: scroll;
    gap: 16px;
    padding-top: 32px;
    padding-left: 32px;
    background-color: var(--background-color);

}

.MainPageContainer-PostsContainerYOURPOSTS > * > *{
    margin-left: auto;
    margin-right: auto;
}

.MainPageContainer-PostsContainerMAINPAGE > * {
    margin: 0 !important;
}

.MainPageContainer-PostsContainerInnerContainer{
    max-width: 768px;
    background-color: white;
    border-radius: 12px;
}

.SearchBarContainer{
    background-color: white !important;
}

.CreatePostLabels{
    padding-top: 12px;
}

.MakePostInput{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 768px) {
    .MainPageContainer-PostsContainerInnerContainer{
        width: 100%;
    }

    .MainPageContainer-PostsContainerMobile{
        position: absolute;
        width: 100vw !important;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
        margin: 0;
    }

    .MainPageContainer{
        display: absolute;
        width: 100%;
    }
}
