#KRAIGSLIST-TEXT{
    font-family: 'Cinzel';
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: 0em;
    transition: all 1s ease-out;
}

#KRAIGSLIST-TEXT > span {
    color: var(--k-orange) !important
}

.MobileHeader{
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: var(--header-height-full);
    transition: all 0.2s ease-out;
}



.MobileHeader > * {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.MobileHeader{
    margin-left: auto;
    margin-right: auto;
}

.MobileHeaderBurger{
    position: absolute;
    top: 14px;
    right: 14px;
    height: 100px;
}

.Header{
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background-color: white;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

.Header-ButtonsContainer{
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 768px;
    align-items: center;
    margin: 0 20px;
}

.Header-Button{
    color: black !important;
}

#Messages{
    position: relative;
}

.UnreadTotalMessageValue{
    background-color: var(--k-orange);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    top: -6px;
    right: -16px;
    color: white;
}

.UnreadTotalMessageValueHide{
    display: none;
    right: 0;
}

@media screen and (max-width: 768px) {
    .Header{
        position: absolute;
        width: 100%;
        height: calc(100% - var(--header-height-full));
        transform: translateX(-100%);
        height: 100vh;
        z-index: 100 ;
    }

    .HeaderOpen{
        transform: translateX(0%) !important;
    }
    
    .Header-ButtonsContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 48px;
    }

    #KRAIGSLIST-TEXT{
        font-size: 44px;
        font-weight: 400;
        line-height: 64px;
        letter-spacing: 0em;

    }
}
    
