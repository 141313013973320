@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

:root{
 --k-orange: #D96D1F;
 --orange: #d86825;
 --orange-selection: #e68d4d;
 --background-color: #EDEEF0;
 --header-height: 96px;
 --header-height-full: 156px;
 --negative-header-full: -156px;
 --message-background: #6ca2b659;
 --chakra-colors-blue-100: var(--chakra-colors-orange-100) !important;
 --chakra-colors-blue-500: var(--chakra-colors-orange-500) !important;

}



body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color) !important;
  background-repeat: repeat !important;
}


#root{
  height: 100%;
  width: 100%;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divCenter{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  font-size: 32px;
}

.NoSelection{
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}


#MessagesContainer-Scroll::-webkit-scrollbar-track
{
  -webkit-overflow-scrolling: auto !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#MessagesContainer-Scroll:-webkit-scrollbar
{
  -webkit-overflow-scrolling: auto !important;
  width: 12px;
  background-color: #F5F5F5;
}

#MessagesContainer-Scroll::-webkit-scrollbar-thumb
{
  -webkit-overflow-scrolling: auto !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #D62929;
}

.HideWhenNONMobile{
  display: none !important;
}

.HideWhenMobile{
  display: block !important;
}

.chakra-modal__close-btn{
  z-index: 100;
}

.Highlighted{
  text-decoration: underline;
}

.Link{
  color: var(--k-orange);
  cursor: pointer;
}

.ZIndex100{
  z-index: 100 !important;
}

.ToastContainerAccess > *{
  background-color: #6ca2b659 !important;
}

.FlexRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.TextItalic{
  font-style: italic;
}

@media screen and (max-width: 768px) {

  :root{
 
    --header-height-full: 80px !important;
    --negative-header-height-full: -80px !important;
   }

  .HideWhenMobile{
    display: none !important;
  }
  .HideWhenNONMobile{
    display: block !important;
  }
  body {
    max-width: 100%;
    overflow: hidden;
  }
}