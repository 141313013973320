.MessagesPageContainer { 
    display: flex;
    height: calc( 100vh - var(--header-height-full));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
 }

.MessagesPageContainer-Main{
    display: flex;
    flex-direction: row;
    margin: auto;
    width: calc(100% - 256px);
    height: calc(100% - 48px);
    max-height: calc(100% - 48px);
    border : 1px solid #e2e8f0;
    border-radius : 12px; 
    background-color: white;

}

.ConversationContainer {
    width: 30% !important;
    height: 100%;
    overflow: scroll;
}

.ConversationContainer-Conversation{
    border-radius: 12px 0px 0px 12px;
}


/* .ConversationContainer-Conversation:first-child {
    border-radius: 12px 0px 0px 0px;
}

.ConversationContainer-Conversation:last-child {
    border-radius: 0px 0px 0px 12px;
} */

.ConversationContainer-Loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    row-gap: 16px;
}

.ConversationContainer-NoConversations{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    row-gap: 8px;
}

.ConversationItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    transition: all 0.1s ease-out;
}

.ConversationItem > div{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MessageContainer{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: hidden;
}

.MessageContainer-NoSelection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
    gap: 16px;
}

.MessageContainer-Selected{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: var(--message-background);
    overflow: scroll;
}



.MessageContainer-Selected-Messages{
    margin-top: auto;
    overflow: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.SelectedMessage > * {
    background-color: var(--k-orange);
    color: white;
}

.UnreadMessage > * {
    background-color: var(--chakra-colors-orange-100)
}

.ConversationContainer{
    overflow: scroll;
}

.ConversationContainer-Conversation{
    overflow: scroll;
    position: relative;
}

.UnreadMessageValue{
    margin-left: auto;
    position:absolute;
    display: flex;
    justify-content: center;
    bottom: 8px;
    right: 8px;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-color: white;
    color: black;
}



.UnreadMessageValueHide{
    display: none;
}


.SendMessageContainer{
    margin: 10px;
}

.SendMessageContainer > * {
    background-color: white;
    border-radius: 10px;
}

.TopMessageContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;
    background-color: white;
    transition: all 0.1s ease-out;
}

.MessageItem{
    width: fit-content;
    max-width: 90%;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 20px 20px 20px 5px;
    margin: 10px;
    background-color: white;
}

.MessageFullContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.MessageFullContainer-Date{
    margin-top: -5px;
    margin-left: auto;
    padding-right: 10px;
}

.MessageFullContainer-Date-Reversed{
    margin-top: -5px;
    margin-right: auto;
    padding-left: 10px;
}

.YourMessage{
    margin-left: auto;
    color: white;
    background-color: var(--k-orange);
    border: 1px solid var(--k-orange);
    border-radius: 20px 20px 5px 20px;
}

.MessageContainer-Selected-Messages-Date{
    width: fit-content;
    margin: 24px auto;
    background-color: #b1c4d572;
    border-radius: 20px;
    padding: 0px 10px;
}

.MessageContainer-SendButton{
    width: 50px;
}

.MessageContainer-LoadMoreSpinner{
    padding-top: 25px;
}

.TopMessageContainerBackButton{
    width: 30px !important;
    height: 30px !important;
    cursor: pointer;
}

@media screen and (max-width: 1080px) {
    .MessagesPageContainer-Main{
        width: 100%;
        width: calc(100% - 128px);
    } 
}

@media screen and (max-width: 768px) {

    .MessagesPageContainer {
        height: calc( 100vh - var(--header-height-full));
        width: 100vw;
        background-color: white;

    }

    
    .MessagesPageContainer-Main{
        border: 0px;
        border-radius: 0px;
        margin-top: 0;
        width: 100%;
    } 

    .ConversationContainer{
        width: 100% !important;
    }

    .MessageContainer{
        z-index: 1;
        transform: translateY(var(--negative-header-height-full)) !important;
        height: calc(100% + var(--header-height-full));
        min-height: calc(100% + var(--header-height-full));
        width: 100vw !important;
        padding-bottom: 10px;
    }



    .TopMessageContainer{
        min-height: var(--header-height-full) !important;
        max-height:  var(--header-height-full) !important;
        flex-direction: row-reverse;
        justify-content: left;
    }
}

