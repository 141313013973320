.FiltersContainer {
    background-color: white;
    height: calc(100vh - var(--header-height-full));

}

.FiltersContainer-InnerContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
}

.FiltersContainer-InnerContainer-Category{
    display: flex;
    flex-direction: column;    
    padding-top: 24px;
}

.FiltersContainer-InnerContainer-Category > *:first-child {
    padding-bottom: 16px;
    width: 100%;
    text-align: center;
}

.FiltersContainer-InnerContainer-Category > *:last-child {
    padding-top: 16px;
    width: 100%;
    text-align: center;
}

.OpenFiltersContainersButton{
    margin-top: 16px;

}

.CloseFiltersContainerButton{
   height: fit-content !important;
}


@media screen and (max-width: 768px) {
    .FiltersContainer{
        position: absolute;
        left: 0;
        width: 100% !important;
        z-index: 2 !important;
        transform: translateX(-100%);
        transition: all 0.2s ease-out;
    }

   
    .FiltersContainerOpen {
        transform: translateX(0%);
    }
}