.Profile-Container {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    border : 1px solid #e2e8f0;
    border-radius : 12px;
    gap: 16px;
    background-color: white;
    margin: auto;
    margin-top: 30px;
    padding: 16px 32px;
}

.Profile-Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0 20px;
}

.Profile-Contents-Container{
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 40px;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
}

.Profile-LeftElt{
    display: flex;
    height: 40px;
    min-width: 100px !important;
    flex-direction: row;
    background-color: var(--k-orange);
    color: white;
    justify-content: left;
    border-radius: 10px 0px 0px 10px;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.Profile-MiddleElt{
    display: flex;
    height: 40px;
    flex-direction: row;
    background-color: white;
    justify-content: center;
    border-radius: 0px 0px 0px 0px;
    padding: 0;
    margin-left: auto;
    text-align: center;
}

.ProfileBottomButtons{
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 450px;
    height: 40px;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .Profile-Container{
        max-width: 100%;
        margin-left: 16px;
        margin-right: 16px;
    }
}
    