.Guideline-ParentContainer{
    height: calc(100vh - var(--header-height-full));
    display: flex;
}

.Guideline-ParentContainer > *{
    margin-left:auto;
    margin-right: auto;
    margin-top: 32px
}

.Guideline-Container{

    
    max-width:900px;
    height: auto;
    border : 1px solid var(--k-orange);
    border-radius : 12px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 16px 32px;

    gap: 16px;
}

.Guideline-Container-Items{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Guideline-Header{
    color: var(--k-orange);
}

.Guideline-Text{
 
}

.Guideline-Text-Items{
 
}

@media screen and (max-width: 768px) {
    .Guideline-Container{
        margin-left: 16px;
        margin-right: 16px;
    }
}
    